// Build information, automatically generated by `ng-info`
const build = {
    version: "21.5.0",
    timestamp: "Mon Jan 13 2025 14:51:45 GMT+0100 (Central European Standard Time)",
    message: null,
    git: {
        user: null,
        branch: "HEAD",
        hash: "ab3a1a",
        fullHash: "ab3a1a7ebe98c635bd7181caa13c0be1fcbb5d34"
    }
};

export default build;